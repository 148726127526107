<template>
  <v-form ref="form" @submit.prevent>
    <v-card
      v-if="!isSuccessful"
      max-width="70vw"
      width="400px"
      class="py-5 px-5 rounded-lg"
    >
      <v-card-title>
        <h1 class="text-h5 text-sm-h4 white-space">Aizmirsi paroli?</h1>
      </v-card-title>
      <v-card-subtitle class="text-subtitle-2 text-sm-subtitle-1 white-space"
        >Lūdzu ievadiet savus datus</v-card-subtitle
      >
      <v-card-text class="my-1">
        <v-text-field
          v-model="password"
          :rules="rules.password"
          variant="underlined"
          color="primary"
          label="Parole"
          required
          hide-details
          prepend-inner-icon="mdi-lock-outline"
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          autocomplete="new-password"
          class="mb-1"
          @click:append-inner="visible = !visible"
        ></v-text-field>
        <v-progress-linear
          v-model="passwordComplexity"
          :color="passwordComplexityColor"
          rounded
        ></v-progress-linear>
        <ul style="list-style-type: none" class="mt-3">
          <li v-for="(item, i) in paswordRules" :key="i" class="mt-1">
            <v-icon
              v-if="correctPasswordRules[i]"
              icon="mdi-check-circle"
              color="success"
            />
            <v-icon
              v-else
              icon="mdi-close-octagon"
              size="large"
              color="error"
            />
            <span class="mx-1">{{ item.text }}</span>
          </li>
        </ul>
        <v-text-field
          v-model="passwordRepeat"
          :rules="rules.passwordRepeat"
          variant="underlined"
          color="primary"
          label="Atkārtota parole"
          required
          prepend-inner-icon="mdi-lock-outline"
          :type="visible ? 'text' : 'password'"
          autocomplete="new-password"
          class="mb-1"
        ></v-text-field>
        <v-alert v-if="error" type="error" class="mt-3">
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <v-btn
          block
          class="mx-0 my-1 py-5"
          variant="flat"
          color="primary"
          :disabled="isLoading"
          :loading="isLoading"
          @click="submitReset"
          >Mainīt paroli</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-card v-else max-width="70vw" width="400px" class="py-5 px-5 rounded-lg">
      <v-card-title>
        <h1 class="text-h5 text-sm-h4 white-space">
          Parole mainīta veiksmīgi!
        </h1>
      </v-card-title>
      <v-card-subtitle class="text-subtitle-2 text-sm-subtitle-1 white-space"
        >Lūdzu dodaties pieslēgties vēlreiz</v-card-subtitle
      >
      <v-card-actions class="d-flex flex-column">
        <v-btn
          block
          class="mx-0 my-1 py-5"
          variant="flat"
          color="primary"
          @click="goTo('login')"
          >Uz pieslēgšanos</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { passwordStrength } from "@/helpers/password";
import { AuthAPI } from "@/api/apis/authActions";

export default {
  name: "ForgotPasswordConfirmView",
  inject: ["cardSize"],
  data: () => {
    return {
      user_id: undefined,
      security_code: undefined,

      password: "",
      passwordRepeat: "",

      isLoading: false,
      isSuccessful: false,
      visible: false,
      error: "",
      paswordRules: [
        {
          text: "Vismaz viens mazais burts",
          rule: (v) => /[a-z]/.test(v),
        },
        {
          text: "Vismaz viens lielais burts",
          rule: (v) => /[A-Z]/.test(v),
        },
        {
          text: "Vismaz viens cipars",
          rule: (v) => /[0-9]/.test(v),
        },
        {
          text: "Vismaz 8 simboli",
          rule: (v) => v.length >= 8,
        },
      ],
    };
  },
  computed: {
    passwordComplexity() {
      return passwordStrength(this.password);
    },
    passwordComplexityColor() {
      const colours = [
        "red-darken-3",
        "orange",
        "yellow",
        "green",
        "green-darken-2",
      ];
      const index = Math.min(Math.floor(this.passwordComplexity / 20), 4);
      return colours[index];
    },
    rules() {
      return {
        password: [
          () =>
            this.correctPasswordRules.every((val) => val === true) ||
            "Lūdzu ievadiet derīgu paroli",
        ],
        passwordRepeat: [
          (v) => !!v || "Lūdzu ievadiet derīgu paroli",
          (v) => v === this.password || "Paroles nesakrīt",
        ],
      };
    },
    correctPasswordRules() {
      return this.paswordRules.map((rule) => rule.rule(this.password));
    },
  },
  created() {
    const query = this.$route.query;
    if (!(query.user_id !== undefined && query.code)) {
      this.goTo("invalid-token");
    } else {
      this.user_id = query.user_id;
      this.security_check();
    }
  },
  methods: {
    goTo(route) {
      this.$router.push({ name: route });
    },
    security_check() {
      this.isLoading = true;
      const query = this.$route.query;
      AuthAPI.resetPasswordSecurityCheck(query.user_id, query.code)
        .then((resp) => {
          this.security_code = resp.data.security_code;
        })
        .catch(() => {
          this.goTo("invalid-token");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async submitReset() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      this.isLoading = true;
      AuthAPI.resetPasswordConfirm(
        this.user_id,
        this.security_code,
        this.password,
        this.passwordRepeat,
      )
        .then((resp) => {
          if (resp.ok) {
            this.isSuccessful = true;
          } else if (resp.code === 410) {
            this.goTo("invalid-token");
          } else {
            const err = resp.response;
            let errStr = "";
            for (const key in err.errors) {
              errStr += err.errors[key][0] + "\n";
            }
            if (!errStr) errStr = err.message;
            this.error = errStr;
          }
        })
        .catch(() => {
          this.error = "Kļūda servera pusē, lūdzu mēģiniet vēlreiz!";
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.fake-a {
  cursor: pointer;
}

.fake-a:hover {
  color: #1976d2;
}
</style>
