<template>
  <div class="content">
    <h1>Kursi</h1>
    <TableContent
      :table-info="userTableInfo"
      name="courses"
      redirect="courses"
    ></TableContent>
  </div>
</template>

<script>
import TableContent from "@/Admin/components/TableContent.vue";
import DeleteAction from "@/Admin/components/form_actions/DeleteAction.vue";
import EditAction from "@/Admin/components/form_actions/EditAction.vue";

export default {
  name: "CoursesListView",
  components: { TableContent },
  data: () => ({
    userTableInfo: {
      headers: [
        {
          title: "Nosaukums",
          align: "start",
          key: "name",
        },
        {
          title: "Pilnā cena",
          align: "start",
          key: "base_price",
        },
        {
          title: "Cilvēku skaits",
          align: "start",
          key: "enrolled_users",
        },
        {
          title: "Darbības",
          align: "start",
          sortable: false,
          key: "actions",
        },
      ],
      actions: [EditAction, DeleteAction],
    },
  }),
};
</script>

<style scoped>
.content {
  width: 100%;
}
</style>
