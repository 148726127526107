import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

// ADMIN
import AdminLayout from "@/Admin/layouts/AdminLayout.vue";
import MainView from "@/Admin/views/MainView.vue";
// ------ users ------
import UserCreateView from "@/Admin/views/users/CreateView.vue";
import UserEditView from "@/Admin/views/users/EditView.vue";
import UserListView from "@/Admin/views/users/ListView.vue";
// ------ user_courses ------
import UserCourseListView from "@/Admin/views/user_courses/ListView.vue";
import LicenseOrderListView from "@/Admin/views/user_courses/LicenseOrderListView.vue";
import LicenseDeliveryListView from "@/Admin/views/user_courses/LicenseDeliveryListView.vue";
// ------ companies ------
import CompanyCoursesListView from "@/Admin/views/companies/CompanyCoursesListView.vue";
import CompaniesCreateView from "@/Admin/views/companies/CreateView.vue";
import CompaniesEditView from "@/Admin/views/companies/EditView.vue";
import CompaniesListView from "@/Admin/views/companies/ListView.vue";
// ------ courses ------
import CoursesCreateView from "@/Admin/views/courses/CreateView.vue";
import CoursesEditView from "@/Admin/views/courses/EditView.vue";
import CoursesListView from "@/Admin/views/courses/ListVIew.vue";
// ------ tests ------
import TestsCreateView from "@/Admin/views/tests/CreateView.vue";
import TestsEditView from "@/Admin/views/tests/EditView.vue";
import TestsListView from "@/Admin/views/tests/ListView.vue";
// ------ articles ------
import AdminArticlesCreateView from "@/Admin/views/articles/CreateView.vue";
import AdminArticlesEditView from "@/Admin/views/articles/EditView.vue";
import AdminArticlesListView from "@/Admin/views/articles/ListView.vue";
// MAIN
import AuthLayout from "@/layouts/AuthLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import MainWithoutFooterLayout from "@/layouts/MainWithoutFooterLayout.vue";
import ProfileLayout from "@/layouts/ProfileLayout.vue";
import QuizLayout from "@/layouts/QuizLayout.vue";
import AboutUsView from "@/views/AboutUsView.vue";
import ContactView from "@/views/ContactView.vue";
import CourseView from "@/views/CourseView.vue";
import CoursesView from "@/views/CoursesView.vue";
import EventsView from "@/views/EventsView.vue";
import LearningCentre from "@/views/LearningCentre.vue";
import ServicesView from "@/views/ServicesView.vue";
import ArticleView from "@/views/articles/ArticleView.vue";
import ArticlesListView from "@/views/articles/ListView.vue";
import AlreadyVerifiedView from "@/views/auth/AlreadyVertifiedView.vue";
import ConfirmEmailView from "@/views/auth/ConfirmEmailView.vue";
import ConfirmForgotPasswordView from "@/views/auth/ForgotPasswordConfirmView.vue";
import ForgotPasswordView from "@/views/auth/ForgotPasswordView.vue";
import InvalidTokenView from "@/views/auth/InvalidTokenView.vue";
import LoginCodeView from "@/views/auth/LoginCodeView.vue";
import LoginView from "@/views/auth/LoginView.vue";
import LogoutView from "@/views/auth/LogoutView.vue";
import RegisterView from "@/views/auth/RegisterView.vue";
import ValidateTokenView from "@/views/auth/ValidateTokenView.vue";
import CheckoutDigitalView from "@/views/checkout/DigitalView.vue";
import CompletedCoursesView from "@/views/profile/CompletedCoursesView.vue";
import ProfileCoursesView from "@/views/profile/CoursesView.vue";
import GeneralView from "@/views/profile/GeneralView.vue";
import InvoiceView from "@/views/profile/InvoiceView.vue";
import InvoicesView from "@/views/profile/InvoicesView.vue";
import QuizOverview from "@/views/quiz/QuizOverview.vue";
import QuizResultView from "@/views/quiz/QuizResultView.vue";
import QuizView from "@/views/quiz/QuizView.vue";
import { UserData } from "@/storage/user";

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      layout: MainLayout,
    },
    component: HomeView,
  },
  {
    path: "/about-us",
    name: "about-us",
    meta: {
      layout: MainLayout,
    },
    component: AboutUsView,
  },
  {
    path: "/learning-center",
    name: "learning-center",
    meta: {
      layout: MainLayout,
    },
    component: LearningCentre,
  },
  {
    path: "/contact",
    name: "contact",
    meta: {
      layout: MainWithoutFooterLayout,
    },
    component: ContactView,
  },
  {
    path: "/services",
    name: "services",
    meta: {
      layout: MainLayout,
    },
    component: ServicesView,
  },
  {
    path: "/courses",
    name: "courses",
    meta: {
      layout: MainLayout,
      backgroundColor: "#F5F5F5",
    },
    component: CoursesView,
  },
  {
    path: "/course/:id",
    name: "course",
    meta: {
      layout: MainLayout,
      backgroundColor: "#F5F5F5",
    },
    component: CourseView,
  },
  {
    path: "/course/:id/test",
    name: "test",
    meta: {
      layout: QuizLayout,
      backgroundColor: "#F5F5F5",
    },
    component: QuizView,
  },
  {
    path: "/course/:id/test-result",
    name: "test-result",
    meta: {
      layout: QuizLayout,
      backgroundColor: "#F5F5F5",
    },
    component: QuizResultView,
  },
  {
    path: "/course/:id/test-overview",
    name: "test-overview",
    meta: {
      layout: QuizLayout,
      backgroundColor: "#F5F5F5",
    },
    component: QuizOverview,
  },
  {
    path: "/login",
    name: "login",
    meta: {
      layout: AuthLayout,
    },
    component: LoginView,
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      layout: AuthLayout,
    },
    component: LogoutView,
  },
  {
    path: "/register",
    name: "register",
    meta: {
      layout: AuthLayout,
    },
    component: RegisterView,
  },
  {
    path: "/login-code",
    name: "login-code",
    meta: {
      layout: AuthLayout,
    },
    component: LoginCodeView,
  },
  {
    path: "/auth/confirm-email",
    name: "confirm-email",
    meta: {
      layout: AuthLayout,
    },
    component: ConfirmEmailView,
  },
  {
    path: "/reset-password",
    name: "reset-password",
    meta: {
      layout: AuthLayout,
    },
    component: ForgotPasswordView,
  },
  {
    path: "/auth/confirm-reset",
    name: "confirm-reset-password",
    meta: {
      layout: AuthLayout,
    },
    component: ConfirmForgotPasswordView,
  },
  {
    path: "/auth/validate",
    name: "validate-email",
    meta: {
      layout: AuthLayout,
    },
    component: ConfirmEmailView,
  },
  {
    path: "/auth/invalid-token",
    name: "invalid-token",
    meta: {
      layout: AuthLayout,
    },
    component: InvalidTokenView,
  },
  {
    path: "/auth/already-verified",
    name: "already-verified",
    meta: {
      layout: AuthLayout,
    },
    component: AlreadyVerifiedView,
  },
  {
    path: "/auth",
    name: "validate-token",
    meta: {
      layout: AuthLayout,
    },
    component: ValidateTokenView,
  },
  // Articles
  {
    path: "/articles",
    name: "articles-list",
    meta: {
      layout: MainLayout,
      backgroundColor: "#F5F5F5",
    },
    component: ArticlesListView,
  },
  {
    path: "/articles/:id",
    name: "article",
    meta: {
      layout: MainLayout,
      backgroundColor: "#F5F5F5",
    },
    component: ArticleView,
  },
  {
    path: "/events",
    name: "events",
    meta: {
      layout: MainLayout,
    },
    component: EventsView,
  },
  // Profile pages
  {
    path: "/profile",
    name: "profile",
    meta: {
      layout: ProfileLayout,
      backgroundColor: "#F5F5F5",
    },
    component: GeneralView,
  },
  {
    path: "/profile/invoices",
    name: "profile-invoices",
    meta: {
      layout: ProfileLayout,
      backgroundColor: "#F5F5F5",
    },
    component: InvoicesView,
  },
  {
    path: "/profile/invoice/:id",
    name: "profile-invoice",
    meta: {
      layout: ProfileLayout,
      backgroundColor: "#F5F5F5",
    },
    component: InvoiceView,
  },
  {
    path: "/profile/courses",
    name: "profile-courses",
    meta: {
      layout: ProfileLayout,
      backgroundColor: "#F5F5F5",
    },
    component: ProfileCoursesView,
  },
  {
    path: "/profile/completed_courses",
    name: "profile-completed-courses",
    meta: {
      layout: ProfileLayout,
      backgroundColor: "#F5F5F5",
    },
    component: CompletedCoursesView,
  },
  {
    path: "/checkout/digital",
    name: "checkout-digital",
    meta: {
      layout: MainLayout,
      backgroundColor: "#F5F5F5",
    },
    component: CheckoutDigitalView,
  },
  // Admin
  {
    path: "/admin",
    name: "admin",
    component: MainView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/users/list",
    name: "admin-users-list",
    component: UserListView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/users/create",
    name: "admin-users-create",
    component: UserCreateView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/users/edit/:id",
    name: "admin-users-edit",
    component: UserEditView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/user_courses/order_list",
    name: "admin-users_courses-order-list",
    component: UserCourseListView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/user_courses/license_order_list",
    name: "admin-users_courses-license-order-list",
    component: LicenseOrderListView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/user_courses/license_delivery_list",
    name: "admin-users_courses-license-delivery-list",
    component: LicenseDeliveryListView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/companies/list",
    name: "admin-companies-list",
    component: CompaniesListView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/companies/create",
    name: "admin-companies-create",
    component: CompaniesCreateView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/companies/edit/:id",
    name: "admin-companies-edit",
    component: CompaniesEditView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/company/courses/:id",
    name: "admin-company-courses-list-view",
    component: CompanyCoursesListView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/courses/list",
    name: "admin-courses-list-view",
    component: CoursesListView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/courses/edit/:id",
    name: "admin-courses-edit-view",
    component: CoursesEditView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/courses/create",
    name: "admin-courses-create-view",
    component: CoursesCreateView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/tests/list",
    name: "admin-tests-list-view",
    component: TestsListView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/tests/edit/:id",
    name: "admin-tests-edit-view",
    component: TestsEditView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/tests/create",
    name: "admin-tests-create-view",
    component: TestsCreateView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/articles/list",
    name: "admin-articles-list-view",
    component: AdminArticlesListView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/articles/create",
    name: "admin-articles-create-view",
    component: AdminArticlesCreateView,
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/articles/edit/:id",
    name: "admin-articles-edit-view",
    component: AdminArticlesEditView,
    meta: {
      layout: AdminLayout,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _from, next) => {
  if (to.meta.layout && to.meta.layout.name === "AdminLayout") {
    if (UserData.Permissions && UserData.Permissions.includes("view.admin")) {
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});

export default router;
