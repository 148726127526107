<template>
  <div class="timer-container">
    <b>{{ minutesLeft }}</b>
    <span class="mr-2">m</span>
    <b>{{ secondsLeft }}</b>
    <span>s</span>
  </div>
</template>

<script>
export default {
  name: "QuizTimer",
  props: {
    endUnixTimestamp: {
      type: Number,
      required: true,
    },
  },
  emits: ["time-is-up"],
  data() {
    return {
      timerInterval: null,
      minutesLeft: "..",
      secondsLeft: "..",
    };
  },
  mounted() {
    this.timerInterval = setInterval(this.updateTimer, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timerInterval);
  },
  methods: {
    $minutesLeft(currentTime) {
      const timeLeft = this.endUnixTimestamp - currentTime;
      return Math.floor(timeLeft / 1000 / 60);
    },
    $secondsLeft(currentTime) {
      const timeLeft = this.endUnixTimestamp - currentTime;
      return Math.floor(timeLeft / 1000) % 60;
    },
    updateTimer() {
      const now = new Date().getTime();
      const min = this.$minutesLeft(now);
      const sec = this.$secondsLeft(now);
      this.minutesLeft = min >= 0 ? min : 0;
      this.secondsLeft = sec >= 0 ? sec : 0;

      if (min <= 0 && sec <= 0) {
        clearInterval(this.timerInterval);
        setTimeout(() => {
          this.$emit("time-is-up");
        }, 1000);
      }
    },
  },
};
</script>
<style scoped>
.timer-container {
  display: flex;
  min-width: 6em;
  justify-content: flex-end;
}
</style>
