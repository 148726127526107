<template>
  <div class="content">
    <h1>Testi</h1>
    <TableContent
      :table-info="userTableInfo"
      name="tests"
      redirect="tests"
    ></TableContent>
  </div>
</template>

<script>
import TableContent from "@/Admin/components/TableContent.vue";
import DeleteAction from "@/Admin/components/form_actions/DeleteAction.vue";
import EditAction from "@/Admin/components/form_actions/EditAction.vue";

export default {
  name: "TestsListView",
  components: { TableContent },
  data: () => ({
    userTableInfo: {
      headers: [
        {
          title: "Nosaukums",
          align: "start",
          key: "title",
        },
        {
          title: "Melnraksts",
          align: "start",
          key: "draft",
        },
        {
          title: "Darbības",
          align: "start",
          sortable: false,
          key: "actions",
        },
      ],
      actions: [EditAction, DeleteAction],
    },
  }),
};
</script>

<style scoped>
.content {
  width: 100%;
}
</style>
