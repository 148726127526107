<template>
  <header ref="navigation-bar">
    <div class="navigation-bar-content">
      <NavigationBarLogo />
      <nav>
        <div v-if="endUnixTimestamp" class="d-flex flex-column align-center">
          <span
            ><b>{{ course.name }}</b></span
          >
          <span class="navbar-subheader"
            >Lūdzu nepametiet šo lapu testa laikā.</span
          >
        </div>
      </nav>
      <QuizTimer
        v-if="endUnixTimestamp"
        :end-unix-timestamp="endUnixTimestamp"
        @time-is-up="submitTest()"
      />
      <div v-else />
    </div>
  </header>
  <v-fade-transition mode="out-in">
    <div
      v-if="testStarted && test && test.questions?.[currentQuestionId]"
      :key="currentQuestionId"
      ref="testCard"
      class="container"
    >
      <div class="page-number">
        <div class="current-page">
          {{ currentQuestionId + 1 }}
        </div>
        <div class="total-pages">
          kopā {{ test.questions.length }} jautājum{{
            test.questions.length !== 1 &&
            (test.questions.length % 10 !== 1 ||
              test.questions.length % 100 === 11)
              ? "i"
              : "s"
          }}
        </div>
      </div>
      <p class="question" v-html="test.questions[currentQuestionId].title"></p>
      <div class="answers">
        <v-radio-group v-model="currentAnswerId" row>
          <v-radio
            v-for="answer in test.questions[currentQuestionId].answers"
            :key="answer.id"
            :label="answer.title"
            :value="answer.id"
            color="primary"
            class="mt-2"
          />
        </v-radio-group>
      </div>
      <div class="btn-actions">
        <v-btn
          color="transparent"
          variant="flat"
          class="text-lowercase prev-btn"
          :disabled="currentQuestionId === 0"
          @click="previousQuestion"
        >
          <v-icon class="mr-3">mdi-arrow-left</v-icon>
          Iepriekšējais jautājums
        </v-btn>
        <v-btn
          v-if="currentQuestionId < test.questions.length - 1"
          color="primary"
          variant="flat"
          class="text-lowercase"
          :disabled="currentAnswerId === null"
          @click="nextQuestion"
        >
          Nākamais jautājums
        </v-btn>
        <v-btn
          v-else
          color="primary"
          variant="flat"
          class="text-lowercase"
          :disabled="currentAnswerId === null"
        >
          Pabeigt testu
          <v-dialog v-model="testSubmitDialog" activator="parent" width="auto">
            <v-card>
              <v-card-text>
                Vai jūs esat pārliecināti ka vēlaties iesniegt testu?
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  color="primary"
                  variant="text"
                  @click="testSubmitDialog = false"
                  >Atcelt</v-btn
                >
                <v-btn
                  color="primary"
                  variant="flat"
                  :loading="submittingTest"
                  @click="submitTest"
                  >Iesniegt testu</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-btn>
      </div>
    </div>
  </v-fade-transition>

  <div v-if="!testStarted" class="pre-test-container">
    <div class="header">
      <span>{{ course.name }}</span>
      <div class="result">
        <v-btn icon="true" size="32" variant="flat">
          <v-icon size="32">mdi-information-outline</v-icon>
          <v-tooltip activator="parent" location="start"
            >Lai tests būtu ieskaitīts, pareizi jāatbild vismaz 50% no
            {{ course.totalQuestions }} jautājumiem</v-tooltip
          >
        </v-btn>
        <span>{{ courseTotalQuestionsText }}</span>
      </div>
    </div>
    <div class="card-content">
      Ja esat gatavi, varat sākt pildīt testu ar pogu "<b>Sākt testu</b>".
      <br />
      Droši varat arī atgriezties uz kursu, un lejupielādēt mācību materiālus
      lai sagatavotos testam.
      <br />
      <br />
      Testa laika limits: <b>{{ course.quizDuration }} minūtes.</b>
    </div>

    <div class="btn-actions">
      <v-btn
        color="transparent"
        variant="flat"
        class="text-lowercase prev-btn"
        @click="returnToCourse()"
      >
        Atgriezties uz kursu
      </v-btn>
      <v-btn
        color="primary"
        variant="flat"
        class="text-lowercase"
        :loading="startingTest"
        @click="startTest()"
      >
        Sākt testu</v-btn
      >
    </div>
  </div>
  <div
    v-if="startingTest"
    class="spinner-container d-flex justify-center align-center"
  >
    <v-progress-circular
      indeterminate
      size="64"
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script>
import config from "@/api/config";
import NavigationBarLogo from "@/components/NavigationBarLogo.vue";
import QuizTimer from "@/components/QuizTimer.vue";
import { UserData } from "@/storage/user";

export default {
  name: "QuizView",
  components: {
    QuizTimer,
    NavigationBarLogo,
  },
  data() {
    return {
      course: {
        name: null,
        totalQuestions: null,
        quizDuration: null,
      },
      test: null,
      testStarted: false,
      currentQuestionId: null,
      currentAnswerId: null,
      endUnixTimestamp: null,
      testSubmitDialog: false,
      timeUp: false,
      submittingTest: false,
      startingTest: false,
    };
  },
  computed: {
    courseTotalQuestionsText() {
      return `kopā ${this.course.totalQuestions} jautājum${
        this.course.totalQuestions !== 1 &&
        (this.course.totalQuestions % 10 !== 1 ||
          this.course.totalQuestions % 100 === 11)
          ? "i"
          : "s"
      }`;
    },
  },
  watch: {
    currentQuestionId(data) {
      this.currentAnswerId = null;
      const currentQuestion = this.test.questions[data].answers.find(
        (answer) => answer.correct,
      );
      if (currentQuestion) this.currentAnswerId = currentQuestion.id;
    },
  },
  async mounted() {
    if (!UserData.IsCompanyUser) {
      await fetch(`${config.baseURL}/user_course/${this.$route.params.id}`, {
        method: "GET",
        headers: config.headers,
      })
        .then((response) => {
          if (!response.ok) throw new Error("Something went wrong.");
          return response.json();
        })
        .then((response) => {
          this.course = {
            name: response.data.course.name,
            totalQuestions: response.data.test_question_count,
            quizDuration: response.data.test_time,
          };

          if (response.data.is_started) this.startTest();
        })
        .catch(() => {
          this.$router.push({
            name: "course",
            params: {
              id: this.$route.params.id,
            },
          });
        });
    } else {
      await fetch(`${config.baseURL}/company_user`, {
        method: "GET",
        headers: config.headers,
      })
        .then((response) => {
          if (!response.ok) throw new Error("Something went wrong.");
          return response.json();
        })
        .then((response) => {
          this.course = {
            name: response.data.course.name,
            totalQuestions: response.data.test_question_count,
            quizDuration: response.data.test_time,
          };

          if (response.data.is_started) this.startTest();
        })
        .catch(() => {
          this.$router.push({
            name: "course",
            params: {
              id: this.$route.params.id,
            },
          });
        });
    }
  },
  methods: {
    async startTest() {
      this.startingTest = true;
      this.testStarted = true;
      await fetch(`${config.baseURL}/test/start/${this.$route.params.id}`, {
        method: "GET",
        headers: config.headers,
      })
        .then((response) => {
          if (!response.ok) {
            this.$router.push({
              name: "course",
              params: {
                id: this.$route.params.id,
              },
            });
          }
          return response.json();
        })
        .then((response) => {
          this.test = response.test;
          if (response.progress) {
            this.test = JSON.parse(response.progress);
          }
          this.currentQuestionId = 0;
          this.endUnixTimestamp = +(response.valid_until + "000");
        })
        .finally(() => {
          this.startingTest = false;
        });
    },
    previousQuestion() {
      if (this.currentAnswerId != null) this.setQuestionAnswer();
      this.currentAnswerId = null;

      if (this.currentQuestionId > 0) {
        this.currentQuestionId--;
      }
    },
    async nextQuestion() {
      this.setQuestionAnswer();
      this.currentAnswerId = null;

      const nextQuestionEmpty = this.test.questions[
        this.currentQuestionId + 1
      ].answers.every((answer) => !answer.correct);

      if (this.currentQuestionId < this.test.questions.length - 1) {
        this.currentQuestionId++;
      }

      if (nextQuestionEmpty) {
        await fetch(
          `${config.baseURL}/test/store_progress/${this.$route.params.id}`,
          {
            method: "POST",
            headers: config.headers,
            body: JSON.stringify({
              test: this.test,
            }),
          },
        );
      }
    },
    setQuestionAnswer() {
      this.test.questions[this.currentQuestionId].answers.forEach((answer) => {
        answer.correct = answer.id === this.currentAnswerId;
      });
    },
    async submitTest() {
      this.setQuestionAnswer();
      this.currentAnswerId = null;

      this.submittingTest = true;

      await fetch(`${config.baseURL}/test_checker/${this.$route.params.id}`, {
        method: "POST",
        headers: config.headers,
        body: JSON.stringify({
          questions: this.test.questions,
        }),
      })
        .then(() => {
          this.$router.push({
            name: "test-overview",
            params: {
              id: this.$route.params.id,
            },
          });
        })
        .finally(() => {
          this.submittingTest = false;
        });
    },
    returnToCourse() {
      this.$router.push({
        name: "course",
        params: {
          id: this.$route.params.id,
        },
      });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 100px;
  margin-top: 120px;
  max-width: 1000px;
  min-height: calc(100vh - 220px);
  margin-inline: auto;
  transition: opacity 0.2s ease;
}

.page-number {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.current-page {
  width: 74px;
  height: 74px;
  background: #1ca7fd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: white;
  font-weight: 600;
}

.total-pages {
  font-size: 26px;
  color: #8c8c8c;
  font-weight: 300;
}

.pre-test-card {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  transition: opacity 0.2s ease;
  background-color: white;
}

.question {
  font-size: 28px;
  color: #262626;
  text-align: center;
}

.answers {
  width: 100%;
}

.prev-btn:disabled {
  opacity: 0.26 !important;
}

.pre-test-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 100px;
  margin-top: 120px;
  max-width: 1000px;
  padding: 15px 20px;
  margin-inline: auto;
  border-radius: 10px;
  transition: opacity 0.2s ease;
  background-color: white;
  box-shadow: 0 16px 64px rgb(0 128 255 / 19%);
}

.navbar-subheader {
  font-size: 14px;
  color: #8c8c8c;
}

.spinner-container {
  height: 100vh;
}

.btn-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

.pre-test-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 28px;
}

.result {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  line-height: 32px;
}

.result > span {
  text-wrap: nowrap;
}

.card-content {
  width: 100%;
  font-size: 18px;
}

@media screen and (width <= 600px) {
  .container {
    padding: 15px 10px;
  }

  .header {
    flex-direction: column;
    gap: 10px;
  }

  .header > span {
    text-align: center;
  }
}

header {
  position: fixed;
  height: 76px;
  transition: 0.2s all;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  width: 100vw;
  top: 0;
  left: 0;
}

.navigation-bar-content {
  max-width: min(80vw, 1453px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav {
  display: flex;
  justify-content: center;
  position: relative;
  gap: 16px;
}

.menu {
  display: none;
}

nav a {
  text-decoration: none;
  font-size: 16px;
  color: black;
  font-weight: normal;
  line-height: 1.5;
  transition: font-weight 0.05s ease;
  text-align: center;
}

nav a:nth-child(1) {
  width: 85px;
}

nav a:nth-child(2) {
  width: 154px;
}

nav a:nth-child(3) {
  width: 118px;
}

nav a:nth-child(4) {
  width: 59px;
}

nav a:hover {
  color: #0d4b72;
}

nav a:active {
  color: #1ca7fd;
  font-weight: 600;
}

nav a.router-link-active {
  color: #1ca7fd;
}

.profile-icon {
  border-radius: 50%;
}

@media only screen and (width <= 1280px) {
  nav {
    gap: 10px;
  }

  nav a {
    font-size: 12px;
  }

  nav a:nth-child(1) {
    width: 64px;
  }

  nav a:nth-child(2) {
    width: 115px;
  }

  nav a:nth-child(3) {
    width: 89px;
  }

  nav a:nth-child(4) {
    width: 46px;
  }

  .navigation-bar-content {
    max-width: 94vw;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (width <= 800px) {
  nav,
  .button-container {
    display: none;
  }

  .menu {
    display: initial;
  }

  .menu-item {
    text-decoration: none;
    font-size: 18px;
    color: black;
    font-weight: normal;
    line-height: 40px;
  }

  .btn-actions {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
