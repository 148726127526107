<template>
  <div class="container">
    <div class="header">
      <h1>Rekvizīti</h1>
      <h2>SIA "Guna BSK”</h2>
    </div>
    <div class="d-flex flex-column" style="gap: 50px">
      <div class="content d-flex align-center">
        <div>
          <p class="content-header">KONTAKTI</p>
          <!--          <h2 class="content-title">Guna Gausiņa-Šļahota</h2>-->
          <ul>
            <li>
              <b>Izglītības iestāde:</b> Profesionālais tālākizglītības centrs
              "Drošam darbam"
            </li>
            <li>
              <b>Juridiskā adrese:</b> Saulaine 21-29, Rundāles pag., Bauskas
              novads, LV 3901
            </li>
            <li><b>PVN reģistrācijas Nr.:</b> LV43603027411</li>
            <li><b>Reģistrācijas Nr.:</b>43603027411</li>
            <li><b>Biroja adrese:</b> Uzvaras ielā 3, Bauska</li>
          </ul>
        </div>
        <v-divider vertical></v-divider>
        <div>
          <span class="d-flex"
            ><v-icon icon="mdi-phone"></v-icon
            ><a href="tel:63925251">63925251</a></span
          >
          <span class="d-flex"
            ><v-icon icon="mdi-phone"></v-icon
            ><a href="tel:29908060">29908060</a></span
          >
          <span class="d-flex"
            ><v-icon icon="mdi-email"></v-icon
            ><a href="mailto:guna.bsk@gmail.com">guna.bsk@gmail.com</a></span
          >
        </div>
      </div>
    </div>
    <div class="d-flex flex-column" style="gap: 50px">
      <div class="content d-flex align-center">
        <div>
          <p class="content-header">VALDES LOCEKLE</p>
          <h2 class="content-title">Guna Gausiņa-Šļahota</h2>
          <ul>
            <li>Jaunu līgumu slēgšana;</li>
            <li>Darba aizsardzība;</li>
            <li>Darba aizsardzības pārraudzība Jūsu uzņēmumā.</li>
          </ul>
        </div>
        <v-divider vertical></v-divider>
        <div>
          <span class="d-flex"
            ><v-icon icon="mdi-phone"></v-icon
            ><a href="tel:27770321">27770321</a></span
          >
          <span class="d-flex"
            ><v-icon icon="mdi-phone"></v-icon
            ><a href="tel:29908060">29908060</a></span
          >
          <span class="d-flex"
            ><v-icon icon="mdi-email"></v-icon
            ><a href="mailto:guna.bsk@gmail.com">guna.bsk@gmail.com</a></span
          >
        </div>
      </div>
      <div class="content d-flex align-center">
        <div>
          <p class="content-header">MĀCĪBU CENTRS</p>
          <h2 class="content-title">Administrātore</h2>
        </div>
        <v-divider vertical></v-divider>
        <div>
          <span class="d-flex"
            ><v-icon icon="mdi-phone"></v-icon
            ><a href="tel:26559582">27770321</a></span
          >
          <span class="d-flex"
            ><v-icon icon="mdi-email"></v-icon
            ><a href="mailto:info@drosamdarbam.lv">
              info@drosamdarbam.lv</a
            ></span
          >
          <span class="d-flex"
            ><v-icon icon="mdi-email"></v-icon
            ><a href="mailto:guna.bsk@gmail.com">guna.bsk@gmail.com</a></span
          >
        </div>
      </div>
    </div>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2207.6088378887384!2d24.182220312995224!3d56.4055670414133!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e8c62b0a1e9359%3A0x2e0b8f658d491c8d!2sUzvaras%20iela%203%2C%20Bauska%2C%20Bauskas%20pils%C4%93ta%2C%20Bauskas%20novads%2C%20LV-3901!5e0!3m2!1sen!2slv!4v1712966181568!5m2!1sen!2slv"
      width="600"
      height="450"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "AboutUsView",
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 100px;
  margin-bottom: 100px;
  margin-top: 100px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 50px;
}

.header > h1 {
  margin-top: 50px;
  font-size: 56px;
  text-align: center;
}

ul {
  margin-left: 20px;
}

span {
  font-weight: 600;
  font-size: 20px;
  gap: 10px;
}

a:active,
a:hover,
a:link {
  color: #000;
  text-decoration: unset;
}

.content {
  gap: 50px;
  width: 100%;
}

.content > div:first-child {
  width: 500px;
}

.content-header {
  color: #188ed7;
  font-size: 22px;
  font-weight: 250;
  letter-spacing: 3.6px;
  text-align: left;
}

.content-title {
  font-size: 42px;
}

@media only screen and (width <= 1290px) {
  .content {
    align-items: start !important;
    justify-content: start !important;
    flex-direction: column;
    gap: 10px;
  }

  .content > div:first-child {
    width: auto;
  }

  span {
    font-size: 16px;
  }
}

@media only screen and (width <= 1000px) {
  .header > h1 {
    font-size: 46px;
  }

  .content-header {
    font-size: 18px;
  }

  .content-title {
    font-size: 32px;
  }
}

@media only screen and (width <= 890px) {
  .container {
    gap: 50px;
  }

  .header > h1 {
    font-size: 46px;
  }

  .content-header {
    font-size: 18px;
  }

  .content-title {
    font-size: 32px;
  }

  .v-divider {
    display: none;
  }
}
</style>
