<template>
  <v-form ref="form" @submit.prevent>
    <v-card
      :max-width="cardSize.maxWidth"
      :width="cardSize.width"
      :class="cardSize.class"
    >
      <v-card-title>
        <h1 class="text-h5 text-sm-h4 white-space">
          Jums ir nosūtīts apstirinājums!
        </h1>
      </v-card-title>
      <v-card-subtitle class="text-subtitle-2 text-sm-subtitle-1 white-space"
        >Lūdzu sekojiet dotajām instrukcijām
        <span class="text-no-wrap">e-pastā</span></v-card-subtitle
      >
      <v-card-text class="my-1">
        <v-alert v-if="error" type="error" class="mt-3">
          {{ error }}
        </v-alert>
        <v-alert v-if="success" type="success" class="mt-3">
          {{ success }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <v-btn
          block
          class="mx-0 my-1 py-5"
          variant="flat"
          color="primary"
          type="submit"
          :disabled="isLoading || recentlySent"
          :loading="isLoading"
          @click="resendConfirm"
          >Atkārtoti nosūtīt</v-btn
        >
        <p class="mt-8">
          Nav konta?
          <span class="fake-a text-blue" @click="goTo('register')"
            >Reģistrēties</span
          >
        </p>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { AuthAPI } from "@/api/apis/authActions";
export default {
  name: "ConfirmEmailView",
  inject: ["cardSize"],
  data: () => {
    return {
      user: {
        email: "",
        id: "",
      },
      error: "",
      success: "",
      isLoading: false,
      recentlySent: false,
    };
  },
  created() {
    const a = this.$route.query;
    this.user.id = parseInt(a.id);
    this.user.email = decodeURIComponent(a.email);
    if (!(this.user.id !== undefined && this.user.email)) {
      this.$router.push({ name: "register" });
    }
  },
  methods: {
    goTo(route) {
      this.$router.push({ name: route });
    },
    resendConfirm() {
      this.isLoading = true;
      this.recentlySent = true;
      setTimeout(() => {
        this.recentlySent = false;
      }, 10000);
      this.success = "";
      AuthAPI.resendValidationEmail(this.user.id, this.user.email)
        .then(() => {
          this.success =
            "E-pasts ir veiksmīgi nosūtīts! Pārbaudiet savu e-pastu.";
        })
        .catch((err) => {
          let errStr = "";
          for (const key in err.errors) {
            errStr += err.errors[key][0] + "\n";
          }
          this.error = errStr;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.fake-a {
  cursor: pointer;
}

.fake-a:hover {
  color: #1976d2;
}
</style>
