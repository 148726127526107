export const maxSeverity = parseInt(process.env.VUE_APP_SEVERITY_LOG);
export function log(source: string, message: any, severity: number = 7) {
  if (severity <= maxSeverity) {
    // eslint-disable-next-line no-console
    console.log(
      `[${new Date().toISOString().split("T")[1].slice(0, -1)}] ${source} - `,
      message,
    );
  }
}
