import { createApp } from "vue";

// Vuetify
import "@mdi/font/css/materialdesignicons.css";
import "@/styles/main.scss";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { lv } from "vuetify/locale";
import "@/Admin/assets/style/style.css";
import "@/assets/style/main.css";

// calendar
import calendar from "@zantixgroup/vue-calendar";
import "@zantixgroup/vue-calendar/dist/style.css";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { log } from "./helpers/debugLog";

document.title = "Drošam darbam";

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: "#2196f3",
        },
      },
    },
    defaultTheme: "light",
  },
  locale: {
    locale: "lv",
    fallback: "lv",
    messages: { lv },
  },
});

createApp(App).use(vuetify).use(store).use(router).use(calendar).mount("#app");
log("Base", "Start of log", 0);
