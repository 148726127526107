<template>
  <v-form ref="form" @submit.prevent>
    <v-card
      :max-width="cardSize.maxWidth"
      :width="cardSize.width"
      :class="cardSize.class"
    >
      <v-card-title>
        <h1 class="text-h5 text-sm-h4 white-space">Vairs nav derīgs!</h1>
      </v-card-title>
      <v-card-subtitle class="text-subtitle-2 text-sm-subtitle-1 white-space"
        >Dotā saite vairs nav derīga, lūdzu atgriežaties uz pierakstīšanos!
      </v-card-subtitle>
      <v-card-actions class="d-flex flex-column">
        <v-btn
          block
          class="mx-0 my-1 py-5"
          variant="flat"
          color="primary"
          @click="goTo('login')"
          >Pieslēgties</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  name: "InvalidTokenView",
  inject: ["cardSize"],
  methods: {
    goTo(route) {
      this.$router.push({ name: route });
    },
  },
};
</script>
