<template>
  <v-main>
    <MainNavigationBar />
    <error-dialog />
    <slot />
    <MainFooter />
  </v-main>
</template>

<script>
import MainNavigationBar from "@/components/MainNavigationBar.vue";
import MainFooter from "@/components/MainFooter.vue";
import ErrorDialog from "@/components/ErrorDialog.vue";

export default {
  name: "MainLayout",
  components: {
    MainFooter,
    MainNavigationBar,
    ErrorDialog,
  },
};
</script>
