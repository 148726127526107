<template>
  <picture :class="classList">
    <source :srcset="webpSrc" :sizes="sizes" type="image/webp" />
    <img :src="src" :sizes="sizes" :alt="alt" height="100%" width="auto" />
  </picture>
</template>

<script>
export default {
  name: "ImageWithFallback",
  props: {
    webpSrc: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    sizes: {
      type: String,
      required: false,
      default: null,
    },
    classList: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
