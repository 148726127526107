<template>
  <div class="content">
    <div class="container">
      <div class="header">
        <div class="search">
          <v-text-field
            v-model="searchName"
            class="search-bar"
            label="Meklēšana"
            prepend-inner-icon="mdi-magnify"
            density="compact"
            variant="solo-filled"
            single-line
          ></v-text-field>
          <v-btn class="text-lowercase" color="primary" @click="filter()"
            >Meklēt</v-btn
          >
        </div>
      </div>
      <div class="search-result">
        <p v-if="courses.length > 1">
          Tika atrasti {{ courses.length }} atbilstoši kursi
        </p>
        <p v-else-if="courses.length == 0">
          Pēc jusu pieprasijuma netiek atrāsts neviens kurs
        </p>
        <p v-else>Tika atrasts {{ courses.length }} atbilstošs kurss</p>
      </div>
      <v-container class="courses-section-cards-container">
        <CourseCard
          v-for="(course, index) in courses"
          :key="index"
          :course="course"
        />
      </v-container>
    </div>
  </div>
</template>

<script>
import CourseCard from "@/components/CourseCard.vue";
import { CourseAPI } from "@/api/apis/CoursesActions";
export default {
  components: { CourseCard },
  data() {
    return {
      courses: [],
      searchName: "",
    };
  },
  created() {
    this.getCourses();
  },
  methods: {
    async getCourses() {
      await CourseAPI.courses().then((response) => {
        this.courses = response.data;
      });
    },
    async filter() {
      await CourseAPI.coursesFilter(this.searchName).then((response) => {
        this.courses = response.data;
      });
    },
  },
};
</script>
<style scoped>
.content {
  margin-top: 100px;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: end;
  border-bottom: 2px solid #d9d9d9;
  padding: 20px;
}

.search {
  display: flex;
  justify-content: center;
  vertical-align: center;
  flex-wrap: wrap;
  gap: 15px;
}

.search-bar {
  width: 300px;
}

.search-result {
  display: flex;
  justify-content: start;
  text-align: start;
  margin-top: 10px;
  color: #454545;
}

.courses-section-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px;
}
</style>
