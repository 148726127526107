<template>
  <section class="large-navigation-buttons-container">
    <v-card hover class="large-container" @click="$router.push('/events')">
      <v-img
        class="event-note-icon"
        :src="require('@/assets/images/large_buttons/event_note.png')"
      />
      <div class="d-flex justify-space-between flex-column text-container">
        <h2>Apskati mūsu pasākumu kalendāru</h2>
        <p>
          Pasākumu kalendārs ir rīks kas ļauj viegli un pārskatāmi saprast kādi
          pasākumi tiek organizēti tuvākajos laikos.
        </p>
      </div>
    </v-card>
    <v-card hover class="medium-container" @click="dialog = true">
      <v-img
        class="shopping-cart-icon"
        :src="require('@/assets/images/large_buttons/shopping_cart.png')"
      />
      <div class="d-flex justify-space-between flex-column text-container">
        <h2>Skatīt veikalu</h2>
        <p>Atrodiet visas standardizētās uzlīmes kuras jums ir nepieciešamas</p>
      </div>
    </v-card>
    <v-card hover class="small-container" @click="$router.push('/contact')">
      <v-img
        class="description-icon"
        :src="require('@/assets/images/large_buttons/description.png')"
      />
      <div class="d-flex justify-space-between flex-column text-container">
        <h2>Rekvizīti</h2>
        <p>Šeit var redzēt visu aptverošo informāciju par mums.</p>
      </div>
    </v-card>

    <v-dialog v-model="dialog" width="auto">
      <v-card>
        <v-card-text>
          Veikals uz doto brīdi nav pieejams, jo atrodas izstrādes procesā!
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="dialog = false">Aizvērt</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped>
.large-navigation-buttons-container {
  width: 100%;
  max-width: 100%;
  height: 555px;
  display: grid;
  grid-template-areas:
    "large large medium"
    "large large medium"
    "large large small";
  gap: 10px;
}

.large-navigation-buttons-container > div {
  border-radius: 10px;
  padding: 16px;
  color: white;
  position: relative;
  overflow: hidden;
}

.large-container {
  grid-area: large;
  background-color: #70c8fe;
}

.large-container > div > h2 {
  font-size: 61px;
  font-weight: 500;
  z-index: 10;
}

.medium-container {
  grid-area: medium;
  background-color: #71ed84;
}

.small-container {
  grid-area: small;
  background-color: #ff9b85;
}

.medium-container > div > h2,
.small-container > div > h2 {
  font-size: 46px;
  font-weight: 500;
  z-index: 10;
}

.event-note-icon {
  position: absolute;
  bottom: -2rem;
  left: -5em;
  transform: rotate(15.15deg);
  width: 70%;
  height: 80% !important;
  z-index: 1;
}

.shopping-cart-icon,
.description-icon {
  position: absolute;
  bottom: -2rem;
  left: -5rem;
  transform: rotate(15.15deg);
  width: 90%;
  height: 90%;
  z-index: 1;
}

.text-container {
  height: 100%;
}

@media only screen and (width >= 1500px) {
  .shopping-cart-icon,
  .description-icon,
  .event-note-icon {
    left: -10rem;
  }
}

@media only screen and (width <= 800px) {
  .medium-container > div > h2,
  .small-container > div > h2,
  .large-container > div > h2 {
    font-size: 32px;
  }

  .large-navigation-buttons-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .large-navigation-buttons-container > div {
    padding: 20px;
  }

  .event-note-icon {
    left: -10em;
  }

  .shopping-cart-icon,
  .description-icon {
    left: -15rem;
  }
}
</style>
