<template>
  <v-chip variant="flat" :color="activeColor">{{ activeText }}</v-chip>
</template>
<script>
export default {
  props: {
    status: {
      type: Number,
      default: 0,
    },
  },
  data: () => {
    return {
      colors: ["warning", "success", "success", "error"],
      texts: ["Rēķins nosūtīts", "Apmaksāts", "Apstrādāts", "Atcelts"],
    };
  },
  computed: {
    activeColor() {
      return this.colors[this.status ? this.status - 1 : 0];
    },
    activeText() {
      return this.texts[this.status ? this.status - 1 : 0];
    },
  },
};
</script>
