<template>
  <v-card class="section-container">
    <h2 class="title">Pabeigtie kursi</h2>
    <v-data-table-server
      class="data-table"
      :headers="table.headers"
      :items="table.data"
      :items-length="table.meta.total"
      :loading="isLoading"
      @update:options="getData"
    >
      <template #[`item.actions`]="{ item }">
        <v-tooltip
          v-if="!item.course.is_license_card"
          location="top"
          text="Lejupielādēt"
        >
          <template #activator="{ isActive, props }">
            <v-icon
              class="mx-1 my-1"
              v-bind="props"
              :color="isActive ? '#505050' : '#757575'"
              @click="openCertificate(item.certificate_url)"
            >
              mdi-download
            </v-icon>
          </template>
        </v-tooltip>
        <v-tooltip location="top" text="Skatīt">
          <template #activator="{ isActive, props }">
            <v-icon
              class="mx-1 my-1"
              v-bind="props"
              :color="isActive ? '#505050' : '#757575'"
              @click="openResult(item.course.id)"
            >
              mdi-eye-outline
            </v-icon>
          </template>
        </v-tooltip>
      </template>
    </v-data-table-server>
  </v-card>
</template>

<script>
import { ProfileAPI } from "@/api/apis/profileActions";

export default {
  name: "UserCourses",
  data() {
    return {
      table: {
        headers: [
          {
            title: "Nosaukums",
            align: "start",
            sortable: false,
            key: "course.name",
            width: "auto",
          },
          {
            title: "Valīds līdz",
            align: "start",
            sortable: false,
            key: "certificate_valid_until",
            width: "100px",
          },
          {
            title: "Rezūltāts",
            align: "center",
            sortable: false,
            key: "score_string",
            width: "100px",
          },
          {
            title: "Pabeigts",
            align: "start",
            sortable: false,
            key: "completed_at",
            width: "100px",
          },
          {
            title: "Darbības",
            align: "center",
            value: "actions",
            sortable: false,
            width: "150px",
          },
        ],
        data: [],
        meta: {
          itemsPerPage: 10,
          total: 0,
        },
      },
      isLoading: false,
    };
  },
  mounted() {
    this.getData({ page: 1, itemsPerPage: 10 });
  },
  methods: {
    getData(options) {
      this.isLoading = true;
      ProfileAPI.courses(1, options.page, options.itemsPerPage)
        .then((response) => {
          this.table.data = response.data;
          this.table.meta.total = response.meta.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    openResult(courseId) {
      this.$router.push({
        name: "test-overview",
        params: {
          id: courseId,
        },
      });
    },
    openCertificate(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  border-radius: 15px;
}

.title {
  padding: 25px 20px;
}

.data-table :deep(.v-data-table-header__content) {
  color: #757575 !important;
  font-weight: bold !important;
  margin: 0;
  padding: 0;
}
</style>
