<template>
  <MainNavigationBar />
  <v-fade-transition mode="out-in">
    <div
      v-if="test && test.questions?.[currentQuestionId]"
      :key="currentQuestionId"
      ref="testCard"
      class="container"
    >
      <div class="page-number">
        <div class="current-page">
          {{ currentQuestionId + 1 }}
        </div>
        <div class="total-pages">{{ totalQuestionText }}</div>
      </div>
      <p class="question">
        {{ test.questions[currentQuestionId].title }}
        <span v-if="currentAnswerId === null"
          >Šis jautājums netika atbildēts</span
        >
      </p>
      <div class="answers">
        <v-radio-group
          v-model="
            test.questions[currentQuestionId].answers.find((v) => v.correct).id
          "
          row
        >
          <v-radio
            v-for="answer in test.questions[currentQuestionId].answers"
            :key="answer.id"
            :value="answer.id"
            :label="answer.title"
            color="primary"
            :class="`mt-2 ${
              answer.correct && test.questions[currentQuestionId].correct
                ? 'user-selected-correct'
                : answer.correct && !test.questions[currentQuestionId].correct
                  ? 'user-selected-incorrect'
                  : ''
            }`"
            disabled
          />
        </v-radio-group>
      </div>
      <div class="btn-actions">
        <v-btn
          color="transparent"
          variant="flat"
          class="text-lowercase prev-btn"
          :disabled="currentQuestionId === 0"
          @click="previousQuestion"
        >
          <v-icon class="mr-3">mdi-arrow-left</v-icon>
          Iepriekšējais jautājums
        </v-btn>
        <v-btn color="transparent" variant="flat" class="text-lowercase">
          Atgriezties uz kursu
          <v-dialog v-model="confirmDialog" activator="parent" width="auto">
            <v-card>
              <v-card-text>
                Vai jūs esat pārliecināti ka vēlaties beigt testa pārskatu?
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  color="primary"
                  variant="text"
                  @click="confirmDialog = false"
                  >Atcelt</v-btn
                >
                <v-btn color="primary" variant="flat" @click="returnToCourse"
                  >Atgriezties uz kursu</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-btn>
        <v-btn
          v-if="currentQuestionId < test.questions.length - 1"
          color="primary"
          variant="flat"
          class="text-lowercase"
          @click="nextQuestion"
        >
          Nākamais jautājums</v-btn
        >
        <v-btn v-else color="primary" variant="flat" class="text-lowercase">
          Pabeigt pārskatu
          <v-dialog v-model="confirmDialog" activator="parent" width="auto">
            <v-card>
              <v-card-text>
                Vai jūs esat pārliecināti ka vēlaties beigt testa pārskatu?
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  color="primary"
                  variant="text"
                  @click="confirmDialog = false"
                  >Atcelt</v-btn
                >
                <v-btn color="primary" variant="flat" @click="returnToCourse"
                  >Atgriezties uz kursu</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-btn>
      </div>
    </div>
  </v-fade-transition>
</template>

<script>
import config from "@/api/config";
import MainNavigationBar from "@/components/MainNavigationBar.vue";
import { UserData } from "@/storage/user";

export default {
  name: "QuizView",
  components: {
    MainNavigationBar,
  },
  data() {
    return {
      currentQuestionId: null,
      currentAnswerId: null,
      confirmDialog: false,
      test: null,
      fakeAnswer: {
        id: null,
        title: "Fake answer",
        correct: false,
      },
    };
  },
  computed: {
    isLoggedIn() {
      return UserData.IsLoggedIn;
    },
    UserData() {
      return UserData;
    },
    totalQuestionText() {
      return `kopā ${this.test.questions.length} jautājum${
        this.test.questions.length !== 1 &&
        (this.test.questions.length % 10 !== 1 ||
          this.test.questions.length % 100 === 11)
          ? "i"
          : "s"
      }`;
    },
  },
  watch: {
    currentQuestionId(data) {
      this.currentAnswerId = null;
      const currentQuestion = this.test.questions[data].answers.find(
        (answer) => answer.correct,
      );
      if (currentQuestion) this.currentAnswerId = currentQuestion.id;
    },
  },
  mounted() {
    this.getTest();
  },
  methods: {
    async getTest() {
      await fetch(`${config.baseURL}/test/result/${this.$route.params.id}`, {
        method: "GET",
        headers: config.headers,
      })
        .then((response) => {
          if (!response.ok) {
            this.$router.push({
              name: "course",
              params: {
                id: this.$route.params.id,
              },
            });
          }
          return response.json();
        })
        .then((response) => {
          if (response.data) this.test = JSON.parse(response.data);
          this.currentQuestionId = 0;
        });
    },
    previousQuestion() {
      if (this.currentQuestionId > 0) {
        this.currentQuestionId--;
      }
    },
    nextQuestion() {
      if (this.currentQuestionId < this.test.questions.length - 1) {
        this.currentQuestionId++;
      }
    },
    returnToCourse() {
      this.currentAnswerId = null;
      this.$router.push({
        name: "course",
        params: {
          id: this.$route.params.id,
        },
      });
    },
  },
};
</script>

<style scoped>
.user-selected-correct {
  background-color: #64ff64;
  border-radius: 5px;
  opacity: 0.7;
}

.user-selected-incorrect {
  background-color: #ff6464;
  border-radius: 5px;
  opacity: 0.7;
}

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 100px;
  margin-top: 120px;
  max-width: 1000px;
  margin-inline: auto;
  transition: opacity 0.2s ease;
}

.page-number {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.current-page {
  width: 74px;
  height: 74px;
  background: #1ca7fd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: white;
  font-weight: 600;
}

.total-pages {
  font-size: 26px;
  color: #8c8c8c;
  font-weight: 300;
}

.question {
  display: flex;
  flex-direction: column;
  font-size: 28px;
  color: #262626;
  text-align: center;
}

.question span {
  font-size: 18px;
  color: #8c8c8c;
  font-weight: 300;
}

.answers {
  width: 100%;
}

.prev-btn:disabled {
  opacity: 0.26 !important;
}

.btn-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

.container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 28px;
}

@media screen and (width <= 600px) {
  .container {
    padding: 15px 10px;
  }

  .btn-actions {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
