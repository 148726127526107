<template>
  <div class="content">
    <div class="container">
      <div class="header">
        <div class="search">
          <v-text-field
            v-model="searchName"
            class="search-bar"
            label="Meklēšana"
            prepend-inner-icon="mdi-magnify"
            density="compact"
            variant="solo-filled"
            single-line
          ></v-text-field>
          <v-btn class="text-lowercase" color="primary" @click="filter()"
            >Meklēt</v-btn
          >
        </div>
      </div>
      <div class="search-result">
        <p v-if="articles.length > 1">
          Tika atrastas {{ articles.length }} atbilstošas aktualitātes
        </p>
        <p v-else-if="articles.length == 0">
          Pēc jusu pieprasijuma netiek atrāsta neviena aktualitāte
        </p>
        <p v-else>
          Tika atrastas {{ articles.length }} atbilstošas aktualitātes
        </p>
      </div>
      <v-container v-if="!loading" class="articles-section-cards-container">
        <v-row>
          <v-col
            v-for="(article, index) in articles"
            :key="index"
            cols="12"
            md="12"
            lg="6"
          >
            <ArticleCard :article="article" />
          </v-col>
        </v-row>
      </v-container>
      <v-progress-circular
        v-else
        indeterminate
        :size="62"
        :width="6"
        color="primary"
        class="ma-auto ml-auto mt-5"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import ArticleCard from "@/components/ArticleCard.vue";
import { ArticlesAPI } from "@/api/apis/articlesActions";
export default {
  name: "ArticlesListView",
  components: { ArticleCard },
  data() {
    return {
      articles: [],
      searchName: "",
      loading: false,
    };
  },
  created() {
    this.getCourses();
  },
  methods: {
    async getCourses() {
      this.loading = true;
      await ArticlesAPI.articles()
        .then((response) => {
          this.articles = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async filter() {
      this.loading = true;
      await ArticlesAPI.articlesFilter(this.searchName)
        .then((response) => {
          this.articles = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.content {
  margin-top: 100px;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: end;
  border-bottom: 2px solid #d9d9d9;
  padding: 20px;
}

.search {
  display: flex;
  justify-content: center;
  vertical-align: center;
  flex-wrap: wrap;
  gap: 15px;
}

.search-bar {
  width: 300px;
}

.search-result {
  display: flex;
  justify-content: start;
  text-align: start;
  margin-top: 10px;
  color: #454545;
}

.articles-section-cards-container {
  width: 100% !important;
}
</style>
