<template>
  <v-card flat color="rgba(0, 0, 0, 0)">
    <v-card-title><b>Apmaksa</b></v-card-title>
    <v-card-text>
      <p class="mb-4">
        Veicot apmaksu ar rēķinu Jūs saņemsiet ēpastu ar jums izrakstīto rēķinu,
        kā arī instrukcijas kā to apmaksāt.
      </p>
      Rēķina apmaksa notiek sekojoši:
      <ul class="ml-8">
        <li>Jūs saņemat rēķinu ēpastā;</li>
        <li>
          pārskatiet un pārliecinaties ka visi rēķinā norādītie dati ir korekti;
        </li>
        <li>
          caur jūsu personīgo bankas applikāciju veiciet tiešu pārskaitījumu ar
          rēķinā norādīto summu uz rēķinā norādīto “Guna BSK” bankas kontu.
          Obligāti pārskaitījuma pamatojumā vai kommentārā norādiet rēķina
          numuru.
        </li>
        <li>
          Mūsu darbinieki konstatēs veikto apmaksu, un atsaucoties uz rēķina
          numuru jums piešķirs piekļuvi izvēlētajam kursam.
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>
