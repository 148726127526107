<template>
  <img src="@/assets/images/logo.svg" @click="handleImageClick" />
</template>

<script>
import { UserData } from "@/storage/user";

export default {
  name: "NavigationBarLogo",
  methods: {
    handleImageClick() {
      if (!UserData.IsCompanyUser) {
        this.$router.push({ name: "home" });
      }
    },
  },
};
</script>

<style scoped>
img {
  cursor: pointer;
}

@media only screen and (width <= 1280px) {
  img {
    width: 170px;
  }
}
</style>
