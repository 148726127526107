<template>
  <section
    class="offer-section-container d-flex align-center justify-space-between"
  >
    <ImageWithFallback
      class="offer-section-image"
      :webp-src="require('@/assets/images/woman/woman-min.webp')"
      :src="require('@/assets/images/woman/woman-min.png')"
      alt="offer-section-image"
    />
    <div class="offer-section-text">
      <h2 class="section-title">
        Attīstiet savas zināšanas un iegūstiet jaunas iespējas
      </h2>
      <p class="mt-4">
        Mēs piedāvājam plašu kursu klāstu, kuri dos jums iespēju atīstīties sev
        vēlamajā laukā un izcelties darba tirgū ar vērtīgiem sertifikātiem un
        apliecībām.
      </p>
      <v-btn
        variant="flat"
        color="primary"
        class="text-lowercase mt-8"
        @click="$router.push({ name: 'courses' })"
      >
        Skatīt pieejamos kursus
      </v-btn>
    </div>
  </section>
</template>

<script>
import ImageWithFallback from "@/components/ImageWithFallback.vue";
export default {
  components: { ImageWithFallback },
};
</script>

<style scoped>
.offer-section-container {
  margin-left: auto;
  margin-right: auto;
  height: 600px;
}

.offer-section-text {
  margin-left: 5%;
}

.offer-section-text p {
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #262626;
}

.offer-section-image {
  width: 50%;
  height: 100%;
}

.offer-section-text button {
  font-size: 16px !important;
  padding: 13px !important;
  height: 100% !important;
}

@media only screen and (width <= 1280px) {
  .offer-section-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
  }

  .offer-section-text button {
    font-size: 18px !important;
    padding: 16px !important;
    height: auto !important;
  }

  .offer-section-image {
    display: none;
  }
}
</style>
