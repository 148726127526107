<template>
  <v-dialog v-model="visible" width="500" :persistent="isPermanent">
    <v-card>
      <v-card-title class="my-3 mx-2">
        <h1 class="text-h5 text-sm-h4 white-space">{{ title }}</h1>
      </v-card-title>
      <v-card-subtitle
        class="text-subtitle-2 text-sm-subtitle-1 white-space px-4 mx-4"
        >{{ message }}</v-card-subtitle
      >
      <v-card-text v-if="details">
        <v-expansion-panels>
          <v-expansion-panel title="Detalizētāka informācija" :text="details">
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions v-if="!isPermanent">
        <v-spacer></v-spacer>
        <v-btn
          text="Aizvērt"
          variant="text"
          color="error"
          @click="close"
        ></v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <div v-else class="mt-6" />
    </v-card>
  </v-dialog>
</template>
<script>
import { register } from "@/helpers/errorDialog";
export default {
  data() {
    return {
      visible: false,
      isPermanent: false,
      title: "Ir radusies kļūda",
      message: "Sistēmas ir kļūdas stāvoklī",
      details: undefined,
    };
  },
  mounted() {
    register(this.show);
  },
  methods: {
    show(title, message, details, isPermanent) {
      this.title = title;
      this.message = message;
      this.details = details;
      this.isPermanent = isPermanent ?? false;
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
