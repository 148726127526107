import { API } from "../apiActions";
import config from "../config";

type ArticleType = {
  id: number;
  image: string;
  title: string;
  text: string;
  created_at: string;
};

export class ArticlesAPI extends API {
  public static articlesFilter(title: string) {
    return this.__successOnly<ArticleType>(
      fetch(`${config.baseURL}/articles?filter[title]=${title}`, {
        method: "GET",
        headers: config.headers,
      }),
      "/articles",
    );
  }

  public static articlesCalendarFilter(date: string) {
    return this.__successOnly<ArticleType>(
      fetch(
        `${config.baseURL}/articles?filter[event_date_year_month]=${date}`,
        {
          method: "GET",
          headers: config.headers,
        },
      ),
      "/articles",
    );
  }

  public static articles() {
    return this.__successOnly<ArticleType>(
      fetch(`${config.baseURL}/articles`, {
        method: "GET",
        headers: config.headers,
      }),
      "/articles",
    );
  }

  public static article(id: number) {
    return this.__successOnly<ArticleType>(
      fetch(`${config.baseURL}/articles/${id}`, {
        method: "GET",
        headers: config.headers,
      }),
      `/article/${id}`,
    );
  }
}
