<template>
  <section
    class="courses-section-container"
    :class="{ 'courses-section-white': whiteBackgroundColor }"
  >
    <div
      class="w-100 courses-section-content d-flex flex-column align-center justify-space-between"
    >
      <h2 class="section-title mt-10">Tiešsaistē apgūstami kursi</h2>
      <v-container
        class="courses-section-cards-container d-flex align-center justify-center flex-wrap mt-10 mb-10"
      >
        <CourseCard
          v-for="(course, index) in courses"
          :key="index"
          :course="course"
        />
      </v-container>
      <v-btn
        color="primary"
        class="text-lowercase"
        @click="$router.push({ name: 'courses' })"
        >Skatīt visus kursus</v-btn
      >
    </div>
  </section>
</template>

<script>
import CourseCard from "@/components/CourseCard.vue";
import config from "@/api/config";

export default {
  components: { CourseCard },
  props: {
    whiteBackgroundColor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      courses: [],
    };
  },
  async created() {
    await fetch(`${config.baseURL}/courses?page=1&pagination=4`, {
      method: "GET",
      headers: config.headers,
    })
      .then((response) => response.json())
      .then((response) => {
        this.courses = response.data;
      });
  },
};
</script>

<style scoped>
.courses-section-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  width: 100% !important;
}

.courses-section-container::before {
  content: "";
  position: absolute;
  height: 100%;
  transform: translateX(-50%);
  width: 100vw;
  top: 0;
  left: 50%;
  background: #e8f6ff;
}

.courses-section-white::before {
  background: none !important;
}

.courses-section-content {
  height: 100%;
  z-index: 10;
}

.courses-section-cards-container {
  gap: 20px;
  width: 100% !important;
  padding: 0;
}

@media only screen and (width <= 1280px) {
  .courses-section-container::before {
    width: 120vw;
  }

  .courses-section-container {
    width: 90vw;
  }

  .courses-section-content {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-right: 0;
  }
}
</style>
