<template>
  <div class="content">
    <h1>Galvenais panelis</h1>
    <div class="info-card-container ga-6">
      <InfoCard
        v-for="(infoCard, index) in infoCards"
        :key="index"
        :title="infoCard.title"
        :value="infoCard.content"
        :icon="infoCard.icon"
      />
    </div>
  </div>
</template>

<script>
import InfoCard from "@/Admin/components/InfoCard.vue";
import config from "@/api/config";

export default {
  name: "MainView",
  components: { InfoCard },
  data: () => ({
    infoCards: {},
  }),
  mounted() {
    fetch(`${config.baseURL}/admin_statistics`, {
      method: "GET",
      headers: config.headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Nesanāca iegūt statistiku");
        }
        return response.json();
      })
      .then((response) => {
        this.infoCards = response.data;
      });
  },
};
</script>

<style scoped>
.content {
  width: 100%;
}

.info-card-container {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
}
</style>
