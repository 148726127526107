<template>
  <v-container class="form-container rounded-lg">
    <h1>Jauns notikums</h1>
    <CreateForm :fields="fields" name="articles" />
  </v-container>
</template>

<script>
import { markRaw } from "vue";
import CreateForm from "@/Admin/components/CreateForm.vue";
import TextField from "@/Admin/components/form/TextField.vue";
import TextEditorField from "@/Admin/components/form/TextEditorField.vue";
import { ruleSetGen } from "@/Admin/helpers/rules";
import ImagePickField from "@/Admin/components/form/ImagePickField.vue";
import DatePickField from "@/Admin/components/form/DatePickField.vue";
export default {
  name: "CompaniesCreateView",
  components: { CreateForm },
  data() {
    return {
      fields: {
        image: {
          component: markRaw(ImagePickField),
          data: {
            label: "Izvēlieties attēlu",
            name: "image",
            size: 700,
          },
        },
        event_date: {
          component: markRaw(DatePickField),
          data: {
            label: "Notikuma datums un laiks",
            placeholder: "Izvēlaties datumu un laiku",
            name: "event_date",
            rules: ruleSetGen.text(undefined, false),
          },
        },
        title: {
          component: markRaw(TextField),
          data: {
            label: "Nosaukums",
            placeholder: "Ievadiet nosaukumu",
            name: "title",
            rules: ruleSetGen.text("Lūdzu ievadiet derīgu nosaukumu", true, 3),
          },
        },
        text: {
          component: markRaw(TextEditorField),
          data: {
            label: "Teksts",
            placeholder: "Ievadiet tekstu",
            name: "text",
          },
        },
      },
    };
  },
};
</script>

<style scoped>
.form-container {
  width: 1000px;
  margin: auto;
  background-color: white;
  padding: 10px;
}
</style>
