<template>
  <v-form ref="form" @submit.prevent>
    <v-card
      :max-width="cardSize.maxWidth"
      :width="cardSize.width"
      :class="cardSize.class"
    >
      <v-card-title>
        <h1 class="text-h5 text-sm-h4 white-space">Reģistrācija</h1>
      </v-card-title>
      <v-card-subtitle class="text-subtitle-2 text-sm-subtitle-1 white-space"
        >Izveidojiet jaunu kontu</v-card-subtitle
      >
      <v-card-text class="my-1">
        <v-text-field
          v-model="name"
          :rules="rules.name"
          variant="underlined"
          color="primary"
          label="Vārds"
          required
          autocomplete="off"
          class="mb-3"
        ></v-text-field>
        <v-text-field
          v-model="surname"
          :rules="rules.surname"
          variant="underlined"
          color="primary"
          label="Uzvārds"
          required
          autocomplete="off"
          class="mb-1"
        ></v-text-field>
        <v-text-field
          v-model="email"
          :rules="rules.email"
          variant="underlined"
          color="primary"
          label="E-pasts"
          required
          autocomplete="email"
          class="mb-1"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :rules="rules.password"
          variant="underlined"
          color="primary"
          label="Parole"
          required
          hide-details
          prepend-inner-icon="mdi-lock-outline"
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          autocomplete="new-password"
          class="mb-1"
          @click:append-inner="visible = !visible"
        ></v-text-field>
        <v-progress-linear
          v-model="passwordComplexity"
          :color="passwordComplexityColor"
          rounded
        ></v-progress-linear>
        <ul style="list-style-type: none" class="mt-3">
          <li v-for="(item, i) in paswordRules" :key="i" class="mt-1">
            <v-icon
              v-if="correctPasswordRules[i]"
              icon="mdi-check-circle"
              color="success"
            />
            <v-icon
              v-else
              icon="mdi-close-octagon"
              size="large"
              color="error"
            />
            <span class="mx-1">{{ item.text }}</span>
          </li>
        </ul>
        <v-text-field
          v-model="passwordRepeat"
          :rules="rules.passwordRepeat"
          variant="underlined"
          color="primary"
          label="Atkārtota parole"
          required
          prepend-inner-icon="mdi-lock-outline"
          :type="visible ? 'text' : 'password'"
          autocomplete="new-password"
          class="mb-1"
        ></v-text-field>
        <v-alert v-if="error" type="error" class="mt-3">
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <v-btn
          block
          class="mx-0 my-1 py-5"
          variant="flat"
          color="primary"
          type="submit"
          :disabled="isLoading"
          :loading="isLoading"
          @click="submitRegister"
          >reģistrējies</v-btn
        >
        <p class="mt-8">
          Jau esi reģistrējies?
          <span class="fake-a text-blue" @click="goTo('login')"
            >Pieslēgties</span
          >
        </p>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { AuthAPI } from "@/api/apis/authActions";
import { ruleSetGen, ruleSet } from "@/helpers/rules";
import { passwordStrength } from "@/helpers/password";
import { UserData } from "@/storage/user";
export default {
  name: "RegisterView",
  inject: ["cardSize"],
  data: () => {
    return {
      visible: false,
      name: "",
      surname: "",
      email: "",
      password: "",
      passwordRepeat: "",

      isLoading: false,
      error: "",

      paswordRules: [
        {
          text: "Vismaz viens mazais burts",
          rule: (v) => /[a-z]/.test(v),
        },
        {
          text: "Vismaz viens lielais burts",
          rule: (v) => /[A-Z]/.test(v),
        },
        {
          text: "Vismaz viens cipars",
          rule: (v) => /[0-9]/.test(v),
        },
        {
          text: "Vismaz 8 simboli",
          rule: (v) => v.length >= 8,
        },
      ],
    };
  },
  computed: {
    passwordComplexity() {
      return passwordStrength(this.password);
    },
    passwordComplexityColor() {
      const colours = [
        "red-darken-3",
        "orange",
        "yellow",
        "green",
        "green-darken-2",
      ];
      const index = Math.min(Math.floor(this.passwordComplexity / 20), 4);
      return colours[index];
    },
    rules() {
      return {
        name: ruleSetGen.text("Lūdzu ievadiet derīgu vārdu", true, 3),
        surname: ruleSetGen.text("Lūdzu ievadiet derīgu uzvārdu", true, 3),
        email: ruleSet.email,
        password: [
          () =>
            this.correctPasswordRules.every((val) => val === true) ||
            "Lūdzu ievadiet derīgu paroli",
        ],
        passwordRepeat: [
          (v) => !!v || "Lūdzu ievadiet derīgu paroli",
          (v) => v === this.password || "Paroles nesakrīt",
        ],
      };
    },
    correctPasswordRules() {
      return this.paswordRules.map((rule) => rule.rule(this.password));
    },
  },
  created() {
    if (UserData.IsLoggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    goTo(route) {
      this.$router.push({ name: route });
    },
    async submitRegister() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      this.isLoading = true;
      AuthAPI.register({
        firstname: this.name,
        lastname: this.surname,
        email: this.email,
        password: this.password,
        password_confirmation: this.passwordRepeat,
      })
        .then((res) => {
          // If someone has a better idea im all ears
          window.location.replace(
            `/auth/confirm-email?id=${
              res.data.user_id
            }&email=${encodeURIComponent(res.data.email)}`,
          );
        })
        .catch((err) => {
          let errStr = "";
          for (const key in err.errors) {
            errStr += err.errors[key][0] + "\n";
          }
          this.error = errStr;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.fake-a {
  cursor: pointer;
}

.fake-a:hover {
  color: #1976d2;
}
</style>
