<template>
  <v-form ref="form" class="w-100 d-flex flex-column mt-5" @submit.prevent>
    <!--  Success message  -->
    <v-alert
      v-show="success"
      type="success"
      class="mb-5"
      text="Tika pievienots ieraksts"
    ></v-alert>

    <!--  Failure message  -->
    <v-alert
      v-show="failure"
      type="error"
      class="mb-5"
      :text="failureMessage"
    ></v-alert>

    <!--  Form content  -->
    <component
      :is="field.component"
      v-for="(field, index) in fields"
      :key="index"
      :ref="field.data.name"
      v-model="field.data.value"
      :data="field.data"
      class="component"
    >
    </component>

    <!-- Questions -->
    <v-expansion-panels variant="accordion" class="mt-4">
      <v-expansion-panel v-for="(question, index) in newQuestions" :key="index">
        <v-expansion-panel-title v-if="!question.hidden">
          {{ index + 1 }}. {{ question.title }}
        </v-expansion-panel-title>
        <v-expansion-panel-text v-if="!question.hidden">
          <v-form>
            <v-btn
              color="red"
              class="mb-5"
              text="Noņemt jautājumu"
              @click="removeQuestion(question)"
            ></v-btn>
            <v-text-field
              v-model="question.title"
              label="Jautājums"
              placeholder="Ievadiet jautājumu"
              required
              hide-details="auto"
              variant="underlined"
              :rules="questionRule"
            >
            </v-text-field>
            <v-radio-group v-model="correctAnswers[index]">
              <v-container
                v-for="(answer, index1) in question.answers"
                :key="index1"
                class="d-flex align-center justify-start"
              >
                <v-radio
                  :value="answer.id"
                  @input="changeCorrectAnswer(question, answer.id)"
                >
                </v-radio>
                <v-text-field
                  v-model="answer.title"
                  label="Atbilde"
                  placeholder="Ievadiet atbildi"
                  required
                  :rules="answerRule"
                  hide-details="auto"
                  variant="underlined"
                >
                </v-text-field>
                <v-btn
                  color="red"
                  icon="mdi-minus"
                  style="width: 30px; height: 30px; font-size: 12px"
                  @click="removeAnswer(question, answer.id)"
                ></v-btn>
              </v-container>
            </v-radio-group>
            <v-btn
              color="primary"
              text="Pievienot atbildi"
              @click="addAnswer(question)"
            ></v-btn>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn
      color="primary"
      text="Pievienot jautājumu"
      @click="addQuestion()"
    ></v-btn>
    <!-- Submit button -->
    <v-btn
      type="submit"
      block
      class="mt-5"
      text="Izveidot"
      @click="submit"
    ></v-btn>
  </v-form>
</template>

<script>
import config from "@/api/config";
import { ruleSetGen } from "@/Admin/helpers/rules";

export default {
  props: {
    fields: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      answerRule: ruleSetGen.text("Lūdzu ievadiet derīgu atbildi", true, 2),
      questionRule: ruleSetGen.text("Lūdzu ievadiet derīgu jautājumu", true, 2),
      loading: false,
      success: false,
      failure: false,
      failureMessage: null,
      correctAnswers: [],
      newQuestions: [],
    };
  },
  methods: {
    addQuestion() {
      this.newQuestions.push({
        title: "",
        id: Math.floor(Math.random() * 10000000000) + 10000000,
        answers: [
          {
            correct: false,
            title: "",
            id: Math.floor(Math.random() * 10000000000) + 10000000,
          },
        ],
      });
    },
    removeQuestion(question) {
      question.hidden = true;
    },
    changeCorrectAnswer(question, answerId) {
      question.answers = question.answers.map((answer) => {
        answer.correct = false;
        if (answer.id === answerId) {
          answer.correct = true;
        }
        return answer;
      });
    },
    addAnswer(question) {
      question.answers.push({
        correct: false,
        title: "",
        id: Math.floor(Math.random() * 10000000000) + 10000000,
      });
    },
    removeAnswer(question, answerId) {
      question.answers = question.answers.filter(
        (answer) => answer.id !== answerId,
      );
    },
    async submit() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      this.loading = true;
      const data = {};
      for (const key in this.fields) {
        const field = this.$refs[this.fields[key].data.name][0];
        if (field.value === null || field.value === undefined) continue;
        if (typeof field.value === "object") {
          if (field.value[0]) {
            data[field.data.name] = field.value[0];
          } else {
            data[field.data.name] = field.value.id;
          }
        } else {
          data[field.data.name] = field.value;
        }
      }

      localStorage.setItem("form", JSON.stringify(data));

      data.questions = this.newQuestions.filter(
        (question) => question.hidden !== true,
      );
      await fetch(`${config.baseURL}/${this.name}`, {
        method: "POST",
        headers: config.headers,
        body: JSON.stringify(data),
      })
        .then(async (response) => {
          if (response.ok) {
            this.success = true;
            setTimeout(() => {
              this.$router.push(`/admin/${this.name}/list`);
            }, 1000);
          } else {
            const body = await response.json();
            this.failureMessage = body.message || body.errors[0];
            this.failure = true;
          }
        })
        .finally(() => {
          this.loading = false;
          const data = JSON.parse(localStorage.getItem("form"));
          for (const key in data) {
            this.$refs[key][0].data.value = data[key];
          }
        });
    },
  },
};
</script>

<style scoped>
form {
  gap: 5px;
  padding: 10px;
}

.v-selection-control {
  flex: unset !important;
}
</style>
