<template>
  <v-card class="section-container">
    <h2 class="title">Apmaksu vēsture</h2>
    <v-data-table-server
      class="data-table"
      :headers="table.headers"
      :items="table.data"
      :items-length="table.meta.total"
      :loading="isLoading"
      @update:options="getData"
    >
      <template #[`item.number`]="{ item }">
        <a
          :href="`/profile/invoice/${item.id}`"
          @click="
            $router.push({ name: 'profile-invoice', params: { id: item.id } })
          "
          >{{ item.number }}</a
        >
      </template>
      <template #[`item.total`]="{ item }"> {{ item.total }}€ </template>
      <template #[`item.status`]="{ item }">
        <colored-status-chip :status="item.status" />
      </template>

      <template #[`item.actions`]="{ item }">
        <v-tooltip location="top" text="Skatīt">
          <template #activator="{ isActive, props }">
            <v-icon
              class="mx-1 my-1"
              v-bind="props"
              :color="isActive ? '#505050' : '#757575'"
              @click="
                $router.push({
                  name: 'profile-invoice',
                  params: { id: item.id },
                })
              "
            >
              mdi-eye-outline
            </v-icon>
          </template>
        </v-tooltip>
      </template>
    </v-data-table-server>
  </v-card>
</template>

<script>
import { ProfileAPI } from "@/api/apis/profileActions";
import ColoredStatusChip from "@/components/Profile/ColoredStatusChip.vue";

export default {
  name: "UserCourses",
  components: {
    ColoredStatusChip,
  },
  data() {
    return {
      table: {
        headers: [
          {
            title: "Pirkuma Nr.",
            align: "start",
            sortable: false,
            key: "number",
            width: "120px",
          },
          {
            title: "Tips",
            align: "start",
            sortable: false,
            key: "type.name",
            width: "auto",
          },
          {
            title: "Summa",
            align: "start",
            sortable: false,
            key: "total",
            width: "100px",
          },
          {
            title: "Statuss",
            align: "start",
            sortable: false,
            key: "status",
            width: "100px",
          },
          {
            title: "Darbības",
            align: "center",
            value: "actions",
            sortable: false,
            width: "150px",
          },
        ],
        data: [],
        meta: {
          itemsPerPage: 10,
          total: 0,
        },
      },
      isLoading: false,
    };
  },
  mounted() {
    this.getData({ page: 1, itemsPerPage: 10 });
  },
  methods: {
    getData(options) {
      this.isLoading = true;
      ProfileAPI.invoices(options.page, options.itemsPerPage)
        .then((response) => {
          this.table.data = response.data;
          this.table.meta.total = response.meta.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  border-radius: 15px;
}

.title {
  padding: 25px 20px;
}

.data-table :deep(.v-data-table-header__content) {
  color: #757575 !important;
  font-weight: bold !important;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: rgb(var(--v-theme-primary));
}
</style>
