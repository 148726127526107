<template>
  <v-container class="form-container rounded-lg">
    <h1>Izveidot testu</h1>
    <TestCreateForm :fields="fields" name="tests" />
  </v-container>
</template>

<script>
import { markRaw } from "vue";
import TestCreateForm from "@/Admin/components/TestCreateForm.vue";
import TextField from "@/Admin/components/form/TextField.vue";

import { ruleSetGen } from "@/Admin/helpers/rules";
import SelectField from "@/Admin/components/form/SelectField.vue";
export default {
  name: "TestsCreateView",
  components: { TestCreateForm },
  data() {
    return {
      fields: {
        title: {
          component: markRaw(TextField),
          data: {
            label: "Nosaukums",
            placeholder: "Ievadiet nosaukumu",
            name: "title",
            rules: ruleSetGen.text("Lūdzu ievadiet derīgu nosaukumu", true, 3),
          },
        },
        duration: {
          component: markRaw(TextField),
          data: {
            label: "Testa izpildes laiks (minūtēs)",
            placeholder: "Ievadiet laiku",
            name: "duration",
            rules: ruleSetGen.number("Lūdzu ievadiet derīgu laiku", true, 1),
          },
        },
        draft: {
          component: markRaw(SelectField),
          data: {
            label: "Vai ir melnraksts",
            placeholder: "Izvēlieties vai ir publicēts",
            name: "draft",
            items: [
              { id: false, value: "Nē" },
              { id: true, value: "Jā" },
            ],
            itemTitle: "value",
            itemValue: "id",
            rules: [ruleSetGen.fieldManditory()],
          },
        },
      },
    };
  },
};
</script>

<style scoped>
.form-container {
  width: 1000px;
  margin: auto;
  background-color: white;
  padding: 10px;
}
</style>
