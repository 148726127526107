<template>
  <v-container class="form-container rounded-lg">
    <h1>Labot testu</h1>
    <TestEditForm :fields="fields" name="tests" :questions="questions" />
  </v-container>
</template>

<script>
import { markRaw } from "vue";
import TestEditForm from "@/Admin/components/TestEditForm.vue";
import TextField from "@/Admin/components/form/TextField.vue";

import { ruleSetGen } from "@/Admin/helpers/rules";
import config from "@/api/config";
import SelectField from "@/Admin/components/form/SelectField.vue";
export default {
  name: "TestsEditView",
  components: { TestEditForm },
  data() {
    return {
      questions: null,
      fields: {
        title: {
          component: markRaw(TextField),
          data: {
            label: "Nosaukums",
            placeholder: "Ievadiet nosaukumu",
            name: "title",
            rules: ruleSetGen.text("Lūdzu ievadiet derīgu nosaukumu", true, 3),
          },
        },
        duration: {
          component: markRaw(TextField),
          data: {
            label: "Testa izpildes laiks (minūtēs)",
            placeholder: "Ievadiet laiku",
            name: "duration",
            rules: ruleSetGen.number("Lūdzu ievadiet derīgu laiku", true, 1),
          },
        },
        draft: {
          component: markRaw(SelectField),
          data: {
            label: "Vai ir melnraksts",
            placeholder: "Izvēlieties vai ir publicēts",
            name: "draft",
            items: [
              { id: false, value: "Nē" },
              { id: true, value: "Jā" },
            ],
            itemTitle: "value",
            itemValue: "id",
          },
        },
      },
    };
  },
  async created() {
    await fetch(`${config.baseURL}/tests/${this.$route.params.id}`, {
      method: "GET",
      headers: config.headers,
    })
      .then((response) => response.json())
      .then((response) => {
        for (const key in this.fields) {
          this.fields[key].data.value = response.data[key];
        }
        this.questions = response.data.questions;
      });
  },
};
</script>

<style scoped>
.form-container {
  width: 1000px;
  margin: auto;
  background-color: white;
  padding: 10px;
}
</style>
