<template>
  <v-card class="section-container">
    <h2 class="title">Esošie kursi</h2>
    <v-data-table-server
      class="data-table"
      :headers="table.headers"
      :items="table.data"
      :items-length="table.meta.total"
      :loading="isLoading"
      @update:options="getData"
    >
      <template #[`item.restricted_course`]="type">
        <span>{{ courseType(type) }}</span>
      </template>

      <template #[`item.created_at`]="{ item }">
        <span>{{ new Date(item.created_at).toLocaleDateString() }}</span>
      </template>

      <template #[`item.order_completed`]="{ item }">
        <v-chip :color="item.order_completed ? 'success' : 'warning'">
          {{ item.order_completed ? "Apstiprināts" : "Gaida apstiprinājumu" }}
        </v-chip>
        <p></p>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-tooltip location="top" text="Skatīt">
          <template #activator="{ isActive, props }">
            <v-icon
              class="mx-1 my-1"
              v-bind="props"
              :color="isActive ? '#505050' : '#757575'"
              @click="
                $router.push({ name: 'course', params: { id: item.course.id } })
              "
            >
              mdi-eye-outline
            </v-icon>
          </template>
        </v-tooltip>
      </template>
    </v-data-table-server>
  </v-card>
</template>

<script>
import { ProfileAPI } from "@/api/apis/profileActions";
import { courseType } from "@/helpers/enums";

export default {
  name: "UserCourses",
  data() {
    return {
      table: {
        headers: [
          {
            title: "Nosaukums",
            align: "start",
            sortable: false,
            key: "course.name",
            width: "auto",
          },
          {
            title: "Statuss",
            align: "start",
            sortable: false,
            key: "order_completed",
            width: "100px",
          },
          {
            title: "Tips",
            align: "start",
            sortable: false,
            key: "restricted_course",
            width: "100px",
          },
          {
            title: "Pasūtīts",
            align: "start",
            sortable: false,
            key: "created_at",
            width: "100px",
          },
          {
            title: "Darbības",
            align: "center",
            value: "actions",
            sortable: false,
            width: "150px",
          },
        ],
        data: [],
        meta: {
          itemsPerPage: 10,
          total: 0,
        },
      },
      isLoading: false,
    };
  },
  mounted() {
    this.getData({ page: 1, itemsPerPage: 10 });
  },
  methods: {
    courseType,
    getData(options) {
      this.isLoading = true;
      ProfileAPI.courses(0, options.page, options.itemsPerPage)
        .then((response) => {
          this.table.data = response.data;
          this.table.meta.total = response.meta.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  border-radius: 15px;
}

.title {
  padding: 25px 20px;
}

.data-table :deep(.v-data-table-header__content) {
  color: #757575 !important;
  font-weight: bold !important;
  margin: 0;
  padding: 0;
}
</style>
