import { API } from "@/api/apiActions";
import config from "@/api/config";

type CourseType = {
  id: number;
  name: string;
  image: string;
  description: string;
  base_price: number;
  half_price: number;
  enrolled_users: number;
  category: {
    id: number;
    name: string;
    description: string;
    image: string;
  };
  certificate_valid_for: number;
  created_at: string;
  updated_at: string;
};

type UserCourse = {
  id: number;
  certificate_url: string;
  restricted_course: string;
  order_completed: string;
  score: string;
  score_string: string;
  correct_answers: string;
  is_completed: string;
  completed_at: string;
  course: {
    id: number;
    name: string;
  };
  valid_until: string | null;
  certificate_valid_until: string | null;
  is_started: boolean;
  created_at: string;
  test_time: number;
  test_question_count: number;
};

export class CourseAPI extends API {
  public static course(CourseId: number) {
    return this.__successOnly<CourseType>(
      fetch(`${config.baseURL}/courses/${CourseId}`, {
        method: "GET",
        headers: config.headers,
      }),
      "/courses",
    );
  }

  public static coursesFilter(name: string) {
    return this.__successOnly<CourseType>(
      fetch(`${config.baseURL}/courses?filter[name]=${name}`, {
        method: "GET",
        headers: config.headers,
      }),
      "/courses",
    );
  }

  public static courses() {
    return this.__successOnly<CourseType>(
      fetch(`${config.baseURL}/courses`, {
        method: "GET",
        headers: config.headers,
      }),
      "/courses",
    );
  }

  public static userCourse(CourseId: number, checkValidation: number) {
    return this.__successOnly<UserCourse>(
      fetch(`${config.baseURL}/user_course/${CourseId}/${checkValidation}`, {
        method: "GET",
        headers: config.headers,
      }),
      "/courses",
    );
  }

  public static companyUser() {
    return this.__successOnly<UserCourse>(
      fetch(`${config.baseURL}/company_user`, {
        method: "GET",
        headers: config.headers,
      }),
      "/company_user",
    );
  }
}
