<template>
  <div v-if="invoice" class="d-flex flex-column" style="width: 100%">
    <v-card class="section-container py-5 mb-4">
      <v-card-title class="d-flex align-enter flex-wrap">
        <b
          class="text-subtitle-1 text-sm-h5 text-md-h5 text-lg-h4 font-weight-medium"
          style="text-align: middle"
          >Pirkums {{ invoice.number }}
        </b>
        <colored-status-chip class="ml-2" :status="invoice.status" />
      </v-card-title>
      <v-divider />
      <v-card-text class="d-flex flex-column text-high-emphasis">
        <v-row class="my-2">
          <v-col class="d-flex flex-column text-no-wrap">
            <span class="text-body-2 text-md-subtitle-1"
              >Izniegšanas datums: {{ creationDate }}</span
            >
            <span class="text-body-2 text-md-subtitle-1"
              >Pēdējās izmaiņas: {{ lastEditDate }}</span
            >
          </v-col>
          <v-col class="d-flex align-center text-no-wrap flex-wrap justify-end">
            <span class="text-body-2 text-md-subtitle-1 mx-2"
              >Ja ir jautājumi, droši</span
            >
            <v-btn
              variant="flat"
              color="primary"
              class="mx-2 text-lowercase"
              href="mailto:admin@drosamdarbam.lv"
            >
              Sazinaties ar mums</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h6 class="text-h5 mb-2 text-no-wrap">Maksājuma veids</h6>
            <!-- 
              body-1 is closer to design but looks trash
              Should stick to body-2, but sadly The Designer will have a mental breakdown
            -->
            <p class="text-body-1">Tiešais bankas pārskaitījums</p>
          </v-col>
          <v-col class="d-flex flex-column align-end pa-5 text-no-wrap">
            <h6 class="text-h5 mb-2">Dokuments</h6>
            <p class="text-body-1">
              <a :href="invoiceUrl" target="_blank">
                <v-icon>mdi-download</v-icon>
                Lejupielādēt rēķinu
              </a>
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="section-container px-3 py-1">
      <v-card-text
        v-for="(item, i) in invoice.items"
        :key="i"
        class="d-flex justify-space-between px-1"
        style="gap: 15px"
      >
        <div class="d-flex flex-column align-start">
          <span class="text-body-1">{{ item.course.name }}</span>
          <span class="text-grey mt-1" style="font-weight: light">{{
            item.restricted_course ? "Tikai tests" : "Pilns kurss"
          }}</span>
        </div>
        <div>
          <span class="text-no-wrap"
            ><span class="text-grey">1 x </span>{{ item.course.price }} €</span
          >
        </div>
      </v-card-text>
      <v-divider class="mx-1" />
      <v-card-title class="d-flex justify-space-between px-1"
        ><span class="text-body-1">Starpsumma</span>
        <span class="text-body-1"
          >{{ invoice.without_vat }}€</span
        ></v-card-title
      >
      <v-divider class="mx-1" />
      <v-card-title class="d-flex justify-space-between px-1"
        ><span class="text-body-1">PVN</span>
        <span class="text-body-1">{{ invoice.vat }}€</span></v-card-title
      >
      <v-divider class="mx-1" />
      <v-card-title class="d-flex justify-space-between px-1"
        ><b class="text-body-1 font-weight-bold">Summa</b>
        <b class="text-body-1 font-weight-bold"
          >{{ invoice.total }}€</b
        ></v-card-title
      >
    </v-card>
  </div>
</template>

<script>
import { ProfileAPI } from "@/api/apis/profileActions";
import ColoredStatusChip from "@/components/Profile/ColoredStatusChip.vue";
export default {
  name: "InvoiceView",
  components: { ColoredStatusChip },
  data() {
    return {
      invoice: undefined,
    };
  },
  computed: {
    creationDate() {
      return this.invoice.created_at
        ? new Date(this.invoice.created_at).toLocaleDateString("ru")
        : "";
    },
    // BE does not return it.
    lastEditDate() {
      return this.invoice.updated_at
        ? new Date(this.invoice.updated_at).toLocaleDateString("ru")
        : "";
    },
    invoiceUrl() {
      if (this.invoice.invoice_url) return this.invoice.invoice_url;
      return "";
    },
  },
  mounted() {
    this.getInvoice();
  },
  methods: {
    getInvoice() {
      ProfileAPI.invoice(this.$route.params.id).then((response) => {
        this.invoice = response.data;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  border-radius: 15px;
}

a {
  text-decoration: none;
  color: rgb(var(--v-theme-primary));
}
</style>
