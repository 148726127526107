export enum StatusCodes {
  PaymentStarted = 1,
  PaymentSent,
  PaymentComplete,
}

export enum ProductType {
  Digital = 1,
  Physical,
}

export function courseType(isRestricted: boolean) {
  return isRestricted ? "Pilnais" : "Tikai tests";
}
